body::-webkit-scrollbar {
  display: none;
}

.react-chatbot-kit-chat-container {
  width: 100%;
  box-shadow: none;
  border-radius: 0;
  position: initial;
}
.react-chatbot-kit-chat-inner-container {
  height: auto;
  overflow: auto;
  border-radius: 0;
}
.react-chatbot-kit-chat-input-container {
  bottom: 103px;
  border-radius: 0;
  padding: 0;
  background-color: var(--purple-bg);
  display: block;
  z-index: 1010;
}
.react-chatbot-kit-chat-input-container.hide-during-demo {
  display: none;
}
.react-chatbot-kit-chat-message-container {
  padding: 30px 10px 0 10px;
}


.react-chatbot-kit-chatbot-avatar {
  margin-right: 5px;
}
.react-chatbot-kit-chatbot-avatar-wrapper {
  width: 27px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.react-chatbot-kit-chat-bot-user-avatar {
  margin-left: 5px;
}
.react-chatbot-kit-chatbot-avatar svg,
.react-chatbot-kit-chatbot-avatar img {
  height: auto;
  width: 100%;
}
.react-chatbot-kit-chat-bot-user-avatar svg,
.react-chatbot-kit-chat-bot-user-avatar img {
  width: 23px;
  height: 23px;
  background: linear-gradient(180deg, #CEADB9 0%, #7E6E90 100%);
  fill: #fff;
  border-radius: 50%;
}


.react-chatbot-kit-user-chat-message {
  background: var(--purple-bg);
}


.react-chatbot-kit-chat-input-form {
  width: auto;
  max-width: 850px;
  margin: 15px auto 15px;
}
.react-chatbot-kit-chat-input {
  max-width: 100%;
  fill: #F1F0F3;
  stroke-width: 1px;
  stroke: #D2B0BB;
  margin-right: 20px;
}
.react-chatbot-kit-chat-btn-send {
  background-color: transparent;
  margin: 0;
}
.react-chatbot-kit-chat-btn-send:hover {
  background-color: transparent !important;
  background: linear-gradient(180deg, #D2B0BB 0%, #77688C 100%);
  box-shadow: 0px 3px 25px rgba(116, 88, 139, 0.3);
}
.react-chatbot-kit-chat-btn-send svg {
  fill: #D2B0BB;
}


.widget__footer-icons {
  right: 0;
}

.chatbot-date {
  top: 80px;
}

.react-chatbot-kit__widget__wrapper.watchlists {
  max-width: none;
}

@media(min-width: 768px) {
  .react-chatbot-kit__widget__wrapper {
    max-width: calc(100% - 56px);
  }
  .react-chatbot-kit-chat-message-container {
    padding: 30px 50px 65px 0;
  }
  .react-chatbot-kit-chat-message-container>div.react-chatbot-kit__widget__wrapper.stories {
    margin: 0 15px;
  }
  .react-chatbot-kit__widget__wrapper.watchlists {
    padding-left: 30px;
  }
}

@media(min-width: 1024px) {
  .chatbot-date.expanded {
    transform: translateX(-75%);
  }
}


@media(min-width: 2800px) {
  .react-chatbot-kit-chat-message-container {
    max-width: 1530px;
    padding: 60px 90px 130px 0;
  }
  .react-chatbot-kit__widget__wrapper {
    max-width: 1316px;
  }

  .react-chatbot-kit-chat-bot-message,
  .react-chatbot-kit-user-chat-message {
    max-width: 1316px;
  }

  .react-chatbot-kit-chat-message-container>div.react-chatbot-kit__widget__wrapper.stories {
    margin: 0 45px;
  }


  .react-chatbot-kit-chat-input-form {
    margin: 30px auto 30px;
    font-size: 24px;
    max-width: 1406px;
  }
  .react-chatbot-kit-chat-input {
    max-width: 1316px;
    padding-left: 30px;
  }
  .react-chatbot-kit-chatbot-avatar-wrapper {
    width: 46px;
    height: 46px;
    margin-right: 10px;
  }
  .react-chatbot-kit-chat-bot-user-avatar img {
    margin-left: 10px;
  }

  .chatbot-date.expanded {
    top: 164px;
  }
  .react-chatbot-kit__widget__wrapper.watchlists {
    padding-left: 60px;
  }
  .react-chatbot-kit-chat-input-container {
    bottom: 250px;
  }
}


@media(max-width: 1024px) {
  .react-chatbot-kit-chat-message-container {
    margin: 0;
    padding: 40px 20px 70px 20px;
    max-width: none;
  }
  .react-chatbot-kit-chat-input-form {
    max-width: 750px;
  }
  .react-chatbot-kit-chat-input-container {
    padding: 0 20px;
    bottom: 113px;
  }
}


@media(max-width: 850px) {
  .react-chatbot-kit-chat-input-form {
    margin-right: 60px;
  }
}

@media(max-width: 800px) {
  .react-chatbot-kit-chat-input-form {
    margin-right: 60px;
  }
}

@media(max-width: 767px) {
  .chatbot-date.expanded {
    top: 90px;
  }
  .react-chatbot-kit-chat-message-container {
    padding: 40px 15px 70px 15px;
  }
  .react-chatbot-kit-chat-input-container {
    padding: 0 15px;
    bottom: 133px;
  }
  .react-chatbot-kit-chat-input {
    margin-right: 10px;
  }
}

@media(max-width: 452px) {
  .react-chatbot-kit-chat-input-container {
    bottom: 198px;
  }
}
